module.exports = {
    errors: {
        required: "This is required field",
        email: "Please enter correct e-mail address",
        agreement: "Agreement is required",
    },
    mail: {
        success: "Thank you. Your message has been sent",
        serverError: "Oops! Something went wrong and we could not send your message. Please try again later.",
        formError: "Oops. You filled in something incorrectly or did not fill it at all. Complete all fields again and try again.",
        error: "Problem processing the form.",
    },
    rodo: {
        agreement: "I agree to the processing of my personal data in accordance with the Law on the protection of personal data in connection with sending a request via the contact-us form. Providing the data is voluntary, but necessary to process the query. I have been informed that I have the right to access my data, the possibility of correcting it, requesting discontinuation of its processing. <br/> The administrator of personal data is: <br/> <strong>Bat in Web Maciej Szałkowski Kazimierza Pulaskiego 14A/2 15-338 Bialystok Podlaskie Voivodeship Poland</strong>",
        checkbox: "I got acquainted with the information about the administrator and data processing.",
    },
}
