import React from "react"
import SEO from "../../components/seo"
import Image from "../../components/image/image"
import ContactForm from "../../components/contact-form"
import { errors, mail, rodo } from "../../data/contact-messages/en"
import { GetSimpleImage } from "../../helpers/get-simple-image"
import "./contact.scss"

const ContactPage = (props) => {
    return (
        <div location={props.location}>
            <SEO title="Contact" language="en"/>
            <div className="contact-background">
                <div className="container contact-wrap">
                    <div className="contact">
                        <div className="details">
                            <div>
                                <Image fluid image={GetSimpleImage("logo.png")[0].node.fluid} alt="logo"
                                       className="logo"/>
                                <p className="detail-hero">
                                    Maciej Szalkowski
                                </p>
                            </div>
                            <p className="adress">
                                Kazimierza Pulaskiego 14A/2<br/>
                                15-338 Bialystok<br/>
                                Poland
                            </p>
                            <div>
                                <div>
                                    <span>Mob:&nbsp;</span>
                                    <a href="tel:+48691836758">
                                        +48 691 836 758
                                    </a>
                                </div>
                                <div>
                                    <span>e-mail:&nbsp;</span>
                                    <a href="mailto:contact@batinweb.com">
                                        contact@batinweb.com
                                    </a>
                                </div>
                            </div>
                            <p className="company-numbers">
                                VAT EU:&nbsp;PL5423115612<br/>
                                CO ID:&nbsp;380526561
                            </p>
                        </div>
                        <div className="contact-form">
                            <h2 className="title">
                                Contact us:
                            </h2>
                            <ContactForm
                                name="Name"
                                emailPlaceholder="e-mail"
                                messagePlaceholder="Message"
                                btnText="Send"
                                sentMessages={mail}
                                validateErrors={errors}
                                rodo={rodo}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactPage
